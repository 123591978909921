import React, { Fragment,useState,useEffect } from 'react';
import Codice from './cmsComponents/Codice';
import Testo from './cmsComponents/Testo';
import Sottotitolo from './cmsComponents/Sottotitolo';
import Divisore from './cmsComponents/Divisore';
export const contentGenerator = (value) => {
    if (value.info === 'codice') {
        return (
            <Codice data={value} />
        )
    }else if(value.info === 'testo'){
        return (
            <Testo data={value.text} />
        )
    }else if(value.info === 'sottotitolo'){
        return (
            <Sottotitolo data={value.text} />
        )
    }else if(value.info === 'divisore'){
        return (
            <Divisore data={value.text} />
        )
    }
}