import React from 'react';
import './App.css';
import Footer from './components/footer';
import Routes from './Routes';

function App() {
  return (
    <div className="App">
      <Routes/>
      <Footer/>
    </div>
  );
}

export default App;
