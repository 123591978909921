import React,{useState,useEffect,Fragment} from 'react';
import {Link} from 'react-router-dom';
import ShowImage from '../ShowImage';
import Menu from '../../Menu';
import {isAuth,getMe} from '../../controllers/loginController';
import {postArticle,getArticles,addPhotoArticle} from '../../controllers/articleController';
import ArticleHome from '../home/ArticleHome';

const AdminDashboard = (props) => {

    const user = isAuth();

    const [fileName,setFileName] = useState('');

    const [articles,setArticles] = useState([])

    const [userData,setUserData] = useState({})

    const [tempContent,setTempContent] = useState('');

    const [tempContentInfo,setTempContentInfo] = useState('');

    const [tempContentCode,setTempContentCode] = useState('');

    const [content,setContent] = useState([]);

    const [article,setArticle] = useState({
        title:'',
        description:'',
        content:[],
        category:''
    })

    const [imageFile,setImageFile] = useState({
        formData:''
    })

    const {formData} = imageFile;

    const init = () => {
        setImageFile({formData:new FormData()})
    }

    useEffect(() => {
        const query = "?limit=2&user="+user.userId;
        getArticles(query)
            .then((res)=>{
                console.log(res.data);
                setArticles(res.data);
            })
        getMe(user.token)
            .then((res)=>{
                setUserData(res.data);
            });
        
        init()
    },[])

    const handleChange = name => event => {
        const value = name === 'file' ? event.target.files[0] : event.target.value;
        if(name === 'file'){
            formData.append(name,value)
            setFileName(value.name);
        }else if(name === 'content'){
            setTempContent(value);
        }else if(name === 'contentInfo'){
            setTempContentInfo(value);
        }else if(name === 'contentCode'){
            setTempContentCode(value);
        }else{
            setArticle({...article,[name]: value})
        }
    }


    const addContent = (info,text,codetype) => {
        
        if(info === "codice"){
            setContent(content.concat({info:info,text:text,codetype:codetype}))
        }else{
            setContent(content.concat({info:info,text:text}))
        }

    }

    const handleRemoveItem = (e) => {
        const info = e.target.getAttribute("info")
        setContent(content.filter(item => item.info !== info));
       };
     

    const submitter = () => {
        setArticle({...article,["content"]:content})  
    }


    const clickSubmit =(event) => {
        event.preventDefault();
        postArticle(user.token,article)
        .then(res=>{
            if (res.error) {
                setArticle({...article,error:res.error})
            }else{
               addPhotoArticle(res.data,formData,user.token)
            }
        }).then(()=>{setArticle(
            {title:'',
            description:'',
            content:[],
            category:''})}).then(()=>{props.history.push(`/`);})
        
    };

    
    return (
        <div>
            <Menu/>
            <br/>

            <div className="sp-w-center">
            <div className=" w-fluid">

                    <form onSubmit={clickSubmit} className="block w-100">
                        <div className="sp-w-center bg-color-fluo ">
                            <h1 className="pd-4">Aggiungi un post</h1>
                        </div>
                        <br/>
                        <div className="sp-w-center">
                            <input type="text" onChange={handleChange('title')}  className="sp-input w-50 txt-m " name="title" id="title" placeholder="titolo"/>
                        </div>
                        <br/>
                        <div className="sp-w-center">
                            <label htmlFor="file"  className="sp-btn bg-color-black color-white">Foto</label>
                        </div>
                        <br/>
                        <input type="file" onChange={handleChange('file')}  accept="image/*" className="sp-inputfile" name="file" id="file"/>
                        
                        <div className="sp-w-center">
                            <input type="text" onChange={handleChange('description')}  className="sp-input w-50 txt-m " name="description" id="description" placeholder="descrizione"/>
                        </div>
                        <br/>
                        <div className="sp-w-center">
                            <input type="text" onChange={handleChange('category')}  className="sp-input w-50 txt-m " name="category" id="cateogry" placeholder="categoria"/>
                        </div>
                        <br/>
                        <div className="sp-w-center">
                            <textarea className="sp-input w-50 txt-xl " onChange={handleChange('content')}  name="content" id="content" placeholder="contenuto"></textarea>
                        </div>
                        <div className="sp-w-center">
                            <input className="sp-input w-50 txt-xl " onChange={handleChange('contentInfo')}  name="contentInfo" id="contentInfo" placeholder="info contenuto"/>
                        </div>
                        <div className="sp-w-center">
                            <input className="sp-input w-50 txt-xl " onChange={handleChange('contentCode')}  name="contentCode" id="contentCode" placeholder="Tipo Codice"/>
                        </div>
                        <div className="sp-w-center">
                            <  button type="button" onClick={()=>{
                                addContent(tempContentInfo,tempContent,tempContentCode)
                                
                            }} className="sp-btn bg-color-fluo color-white">Add</button>
                        </div>
                        <br/>
                        <div className="sp-w-center">
                            <div className="block">
                                {content && 
                                                content.map((obj,i)=>{
                                                    return (
                                                        <div className="flex" key={i}>
                                                            
                                                            <hr/>
                                                            <p className="txt-m">            
                                                                {obj.info}
                                                            </p>
                                                            <button info={obj.info} className="pd-btn sp-btn bg-color-red color-white" onClick={handleRemoveItem}>
                                                                x
                                                            </button>
                                                        </div>
                                                        
                                                    )
                                                })
                                            }
                            </div>
                        </div>
                        <br/>
                        <div className="sp-w-center">
                            <  button type="button" onClick={()=>{
                                submitter()
                            }} className="sp-btn bg-color-black color-white">Formatta Tutto</button>
                        </div>
                        <br/>
                        {
                            article && 
                            <div className="sp-w-center">
                                <div className="block">
                                    <div className="sp-w-center">
                                        <h1>{"Title: "}</h1>
                                    </div>
                                    <div className="sp-w-center">
                                        <p className="txt-l">{article.title}</p>
                                    </div>
                                    <div className="sp-w-center">
                                        <h1>{"Description:"}</h1>
                                    </div>
                                    <div className="sp-w-center">    
                                        <p>{article.description !== "" ? "Presente" : "Assente"}</p>
                                    </div>
                                    <div className="sp-w-center">
                                        <h1>{"Category:"}</h1>
                                    </div>
                                    <div className="sp-w-center">
                                        <p>{article.category}</p>
                                    </div>
                                    <div className="sp-w-center">
                                        <h1>{"Image:"}</h1>
                                    </div>
                                    <div className="sp-w-center">
                                        {
                                            fileName && <p>{fileName}</p>
                                            }
                                    </div>
                                    <div>
                                        <h1>{"content:"}</h1>
                                        
                                        {
                                            article.content.map((obj,i)=>{
                                                return (
                                                    <p key={i}>{obj.info}</p>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        
                        <br/>
                        <div className="sp-w-center">
                            <  button type="submit" className="sp-btn bg-color-fluo color-white">Submit</button>
                        </div>
                        
                    </form>
                </div>
                </div>
                <br/>
                <br/>
                <hr/>
            
            <div className="sp-fluid sp-space-between">
                
                <div className="sp-w-center sp-pd-fluid">
                    <div className="block">
                        <div className="sp-w-center sp-bline">
                            <h1 className="color-black">{ userData.name}</h1>
                        </div>
                        <div className="sp-w-center sp-bline">
                            <h1 className="color-black">{userData.role}</h1>
                        </div>
                        <div className="sp-w-center sp-bline">
                            <h1 className="color-black">{userData.email}</h1>
                        </div>
                    </div>
                </div>

                <div className="sp-w-center sp-pd-fluid">
                    <div className="block">
                        <div className="sp-w-center">
                            <div className="sp-w-center sp-s-txt-container bg-color-fluo ">
                                <h1 className="color-black">I tuoi ultimi due post</h1>
                            </div>
                        </div>
                        <div className="sp-w-center ">
                            <div className="block">
                                {
                                    articles.map((article,index)=>{
                                        return (
                                            <ArticleHome key={index} article = {article} i = {index}>

                                            </ArticleHome>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminDashboard;
