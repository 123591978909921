import React, { Fragment,useEffect,useState } from 'react';
import {Link } from'react-router-dom';
import Menu from '../Menu';
import {getArticles} from '../controllers/articleController';
import ShowImage from './ShowImage';
import '../style/Home.css';
import ArticleHome from './home/ArticleHome';

const Home = () => {

    const [articles,setArticles] = useState([]);

    useEffect(() => {
        const query = "?limit=5";
        getArticles(query).then(res => {
            setArticles(res.data);
        })
    },[])

    return (
        <Fragment>
            <Menu/>
            <div id="home" className="block">
                <br/>
                <br/>
                <div className="sp-w-center first-home-container">
                    <div className="sp-w-center pd-btn bg-color-black color-white smooth">
                        <h1>Last Articles</h1>
                    </div>
                </div>
                
                <div className="home-flexbox">
                    {
                        articles.map((post,index)=>{
                            return (
                                <div className="sp-w-center">
                                    <ArticleHome article = {post} i = {index}>

                                    </ArticleHome>
                                </div>
                            )})
                    }
                </div>

                <Link to="/about" className="solo-button sp-w-center">
                    <button className="pd-btn sp-btn bg-color-orange color-white txt-l" >About Us</button>
                </Link>
               
            </div>
            <br/>
            <br/>
        </Fragment>
        
    )
}

export default Home