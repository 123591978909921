import React, { Fragment,useEffect,useState } from 'react';
import {Link} from 'react-router-dom'
import Menu from '../Menu';
import {getArticles} from '../controllers/articleController';
import ShowImage from './ShowImage';
import '../style/Home.css';
import ArticleHome from './home/ArticleHome';

const Articles = () => {

    const [articles,setArticles] = useState([]);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState('');
    const [category,setCategory] = useState('');

    useEffect(() => {
        let query = '?limit=3&';
        if (page) {
            query = query + 'page='+page.toString();
        }
        if (search !== '') {
            query = query + '&title='+search
        }
        if (category !== '') {
            query = query + '&category='+category
        }
        getArticles(query).then(res => {
            setArticles(res.data);
        })
        
    },[page,search,category])

    const handleChange = name => event => {
        setSearch(event.target.value);
    } 

    const submitted = async (e) => {
        e.preventDefault()
        let query = '?limit=2&';
        if (page) {
            query = query + 'page='+page.toString();
        }
        if (search !== '') {
            query = query + '&title='+search
        }
        getArticles(query).then(res => {
            setArticles(res.data);
        })
 
         
    }

    return (
        <Fragment>
            <Menu/>
            <br/>
                <br/>
            <div className="sp-w-center">
                
                <form onSubmit={submitted} className="sp-flex">
                    <input 
                    name="search"
                    onChange={handleChange('search')}
                    type="text" 
                    className="sp-input " 
                    value={search}
                    placeholder="Search..."/>
                </form>
                <br/>
                <br/>
            </div>
            <div className="sp-w-center button-set">
                <button className={("sp-btn pd-btn color-white txt-l") + (category === 'Coding' ? " orange-hover " : " bg-color-orange ")} onClick={
                    ()=>{
                        if (category === 'Coding') {
                            setCategory('');
                        } else {
                            setCategory('Coding');
                        }
                        
                    }
                }>Coding</button>
                <button className={("sp-btn pd-btn color-white txt-l") + (category === 'Tech' ? " orange-hover " : " bg-color-orange ")} onClick={
                    ()=>{
                        if (category === 'Tech') {
                            setCategory('');
                        } else {
                            setCategory('Tech');
                        }
                    }
                }>Tech</button>
                <button className={("sp-btn pd-btn color-white txt-l") + (category === 'Gaming' ? " orange-hover " : " bg-color-orange ")} onClick={
                    ()=>{
                        if (category === 'Gaming') {
                            setCategory('');
                        } else {
                            setCategory('Gaming');
                        }
                    }
                }>Gaming</button>
            </div>
            <div id="articles" className="sp-w-center">
                <div className="block">
                    <br/>
                    <br/>
                    <div className="home-flexbox">
                         {
                            articles.map((post,index)=>{
                                return (
                                    <div className="sp-w-center">
                                        <ArticleHome article = {post} i = {index}>
    
                                        </ArticleHome>
                                    </div>
                                )})
                        }
                    </div>
                </div>
            </div>
            <div className="sp-w-center ">
                <div className="flex sp-space-between sp-txt-container">
                    <div>
                        {page > 1 && <button onClick={()=>{
                            if (page > 1) {
                                setPage(page-1);
                            }
                        }} className="sp-btn bg-color-orange color-white pd-btn">{page > 1 && page-1}</button>}
                    </div>
                    <div>
                        <button onClick={()=>{
                            setPage(page+1);
                        }} className="sp-btn bg-color-orange color-white pd-btn">{page+1}</button>
                    </div>
                    
                </div>
            </div>
            <br/>
            <br/>
        </Fragment>
        
    )
}

export default Articles