export const sendLogin = (user) => {
    return fetch('https://api.spaghettihacks.com/api/auth/login',
    {
        method: "POST",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body: JSON.stringify(user)
    }).then((res)=>{return res.json()})
}

export const isAuth = () => {
    if (typeof window == 'undefined') {
        return false
    }
    if (localStorage.getItem('token')) {
       const user = {
           userId:localStorage.getItem('userId'),
           role:localStorage.getItem('role'),
           token:localStorage.getItem('token')
       }
       return user
    }else{
        return false;
    }
}

export const getMe = (token) => {
    return fetch('https://api.spaghettihacks.com/api/auth/me',
    {
        method: "GET",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:("Bearer "+token)
        }
    }).then((res)=>{return res.json()})
}