import React, { Fragment,useState,useEffect } from 'react';
import Menu from '../Menu';
import ShowImage from '../layout/ShowImage';
import {getArticle,deleteArticle} from '../controllers/articleController';
import {contentGenerator} from '../controllers/cmsController';
import {isAuth,getMe} from '../controllers/loginController';
import moment from 'moment';
import '../style/Article.css'

const Article = (props) => {

    const user = isAuth();

    const [article,setArticle] = useState({});

    const [userData,setUserData] = useState({})

    useEffect(() => {
        let articleId = props.match.params.articleId;
        getArticle(articleId).then(res => {
            setArticle(res.data);
        })
        getMe(user.token)
            .then((res)=>{
                setUserData(res.data);
            });
    },[props.match.params.articleId])

    

    return (
        <Fragment>
            <Menu/>
            <div className="sp-w-center mb-4">
                <div id="article" className="block">
                    <div className="argument">
                        <p className="txt-l">{article.category}</p>
                    </div>
                    <div className="title">
                        <h1>{article.title}</h1>
                    </div>
                    <div className="writer">
                        <div className="block">
                            <p>{moment(article.createdAt).fromNow()}</p> 
                        </div>
                    </div>
                    <div className="photo">
                        <ShowImage item={article} itype={"w-100 home-img"} />
                    </div>
                    <div className="description">
                        <h3 className="txt-l">{article.description}</h3>
                    </div>
                    <div className="content">
                        {article.content && 
                            article.content.map((a,i)=>{
                                return contentGenerator(a)
                            })
                            
                        }
                    </div>
                </div>
            </div>
            {
                user.userId === article.user && 
                <Fragment>
                    <div className="sp-w-center sp-h-center">
                        <h1>Writer tools</h1>
                        <div className="sp-fluid">
                            <div>
                                <button className="sp-btn bg-color-red pd-btn" onClick={()=>{
                                    deleteArticle(user.token,props.match.params.articleId)
                                    .then(()=>{
                                        props.history.push(`/articles`);
                                    })
                                    }}>
                                    <h1 className="color-white">Delete</h1>
                                </button>
                            </div>   
                            <div>
                                <button className="sp-btn bg-color-black pd-btn">
                                    <h1 className="color-white">Update</h1>
                                </button>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                    <br/>
                    <br/>
                </Fragment>
            }
            
        </Fragment>
        
    )
}

export default Article