import React, { Fragment } from 'react';


const Sottotitolo = ({data}) => {

    return (
        <Fragment>
            <div id="Sottotitolo" className="block">
                <h3>{data}</h3>
            </div>
        </Fragment>
        
    )
}

export default Sottotitolo