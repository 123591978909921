import React from 'react';
import { HashRouter as Router,Switch,Route} from 'react-router-dom';
import Home from './components/layout/Home';
import Articles from './components/layout/Articles';
import Article from './components/layout/Article';
import About from './components/layout/About';
import Login from './components/layout/Login';
import Contacts from './components/layout/Contacts';
import AdminDashboard from './components/layout/user/AdminDashboard';
import AdminRoute from './components/layout/user/AdminRoute';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/articles" exact component={Articles}/>
                <Route path="/about" exact component={About}/>
                <Route path="/contacts" exact component={Contacts}/>
                <Route path="/articles/:articleId" exact component={Article}/>
                <Route path="/login" exact component={Login}/>
                <AdminRoute 
                path="/dashboard" 
                exact component={AdminDashboard}/>
            </Switch>
        </Router>
    )
}

export default Routes