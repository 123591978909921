import React, { Fragment } from 'react';

const Testo = ({data}) => {

    return (
        <Fragment>
            <div id="Testo" className="block">
                <p>{data}</p>
            </div>
        </Fragment>
        
    )
}

export default Testo