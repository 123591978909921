import React, { useState,Fragment } from 'react';
import Menu from '../Menu';
import {sendLogin} from '../controllers/loginController';

const Login = (props) => {

    const [userData,setUserData] = useState({
        email:'',
        password:''
    })

    const [error,setError] = useState('')

    const handleChange = name => event => {
        setUserData({...userData, [name]: event.target.value});
    } 

    const submitted = async (e) => {
         e.preventDefault()
         sendLogin(userData).then(res=>{
             console.log(res);
             return res;
            })
            .then((res)=>{
                if (res.error) {
                    setError(res.error)
                    return (false)
                } else {
                    localStorage.setItem("userId", res.userId); 
                    localStorage.setItem("role", res.userRole);
                    localStorage.setItem("token", res.token);
                    return (true)
                }
            }).then((res)=>{
                if (res) {
                    props.history.push(`/`);
                }
                
            })
            
         
    }

    return (
        <Fragment>
            <Menu/>
            <div className="sp-w-center bg-color-red">
                {error && <h1 className="color-white">{error}</h1>}
            </div>
            <div id="login" className="sp-w-center">
                <form onSubmit={submitted}>
                    <h1>Login Admin</h1>
                    <input 
                    name="email"
                    onChange={handleChange('email')}
                    type="text" 
                    className="sp-input" 
                    value={userData.email}
                    placeholder="email"/>
                    <br/>
                    <input 
                    name="password"
                    onChange={handleChange('password')}
                    type="password" 
                    className="sp-input" 
                    value={userData.password}
                    placeholder="password"/>
                    <br/>
                    <div className="center">
                        <button type="submit" className="sp-btn bg-color-black color-white">Submit</button>
                    </div>
                </form>
            </div>
        </Fragment>
        
    )
}

export default Login