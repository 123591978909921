import React, { Fragment } from 'react';
import Menu from '../Menu';

const Contacts = () => {

    return (
        <Fragment>
            <Menu/>
            <div id="contacts" className="block">
                <h1>Contacts</h1>
            </div>
        </Fragment>
        
    )
}

export default Contacts