import React,{useState} from 'react';
import {Link,withRouter} from 'react-router-dom';
import "./style/Menu.css";
import Sticky from 'react-stickynode';
import {isAuth} from './controllers/loginController';

const Menu = ({history}) => {

    const signout = () => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('role');
            history.push(`/`);
        }
    }

    const [menuClass,setMenuClass] = useState('none');
    const user = isAuth();

    return (
        <Sticky enabled={true} top={0} bottomBoundary={0}>
        <div id="menu" className="bg-color-black">

            {/*menu phone*/}
            
            <div className="menu-phone ">
                <div className="block">
                    
                    <div className="top-menu">
                        <div className="sp-h-center first-mobile">
                            <Link to="/">
                                <h1 className="color-white">SpaghettiHacks</h1>
                            </Link>
                        </div>
                        <button className="sp-btn bg-color-tras" onClick={()=>{
                            if (menuClass === "sp-w-center") {
                                setMenuClass("none")
                            } else {
                                setMenuClass("sp-w-center")
                            }
                        }}>
                            <div className="line2"></div>
                            <div className="line2"></div>
                        </button>
                    </div>
                    
                    <div className={menuClass+" sp-b-effect"}>
                        <div className="sp-y-list ">
                            <div className="sp-w-center">
                                <Link to="/articles">
                                    <button className="sp-btn bg-color-tras color-white txt-m">Articles</button>
                                </Link>
                            </div>
                            <div className="sp-w-center">
                                <Link to="/about">
                                    <button className="sp-btn bg-color-tras color-white txt-m">About</button>
                                </Link>
                            </div>
                            {user && <div className="sp-w-center">
                                    <button className="sp-btn bg-color-tras color-white txt-m" onClick={()=>{
                                        signout()
                                    }}>Logout</button>
                            </div>}
                            {user && <div className="sp-w-center">
                                <Link to="/dashboard">
                                    <button className="sp-btn bg-color-tras color-white txt-m">Dashboard</button>
                                </Link>
                            </div>}
                            {!user && <div className="sp-w-center">
                                <Link to="/login">
                                    <button className="sp-btn bg-color-tras color-white txt-m">Login</button>
                                </Link>
                            </div>}
                                {user && <h1 className="color-fluo"> Welcome {user.role}</h1>}
                        </div>
                    </div>
                    
                </div> 
            </div>
            
            {/*menu computer*/}
            <div className="menu-computer sp-w-center ">
                <div className="block w-50">
                    <div className="sp-h-center sp-space-between ">
                        <div className="sp-h-center first-mobile">
                            <Link to="/">
                                <h1 className="color-white">SpaghettiHacks</h1>
                            </Link>
                        </div>
                        <div className="sp-x-list">
                                <div className="sp-w-center">
                                    <Link to="/articles">
                                        <button className="sp-btn bg-color-tras color-white txt-m">Articles</button>
                                    </Link>
                                </div>
                                {user && <div className="sp-w-center">
                                    <Link to="/dashboard">
                                        <button className="sp-btn bg-color-tras color-white txt-m">Dashboard</button>
                                    </Link>
                                </div>}

                                <div className="sp-w-center">
                                    <Link to="/about">
                                        <button className="sp-btn bg-color-tras color-white txt-m">About</button>
                                    </Link>
                                </div>
                                {!user && <div className="sp-w-center">
                                    <Link to="/login">
                                        <button className="sp-btn bg-color-tras color-white txt-m">Login</button>
                                    </Link>
                                </div>}
                                {user && <div className="sp-w-center">
                                        <button className="sp-btn bg-color-tras color-white txt-m" onClick={()=>{
                                            signout()
                                        }}>Logout</button>
                                </div>}
                                
                        </div>       
                    </div>
                </div>
                  
            </div> 
             
        </div>
        </Sticky>
    )
}

export default withRouter(Menu)