import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import ShowImage from '../ShowImage';
import moment from 'moment';


const ArticleHome = ({article,i}) => {

    return (
        <Fragment key={i}>
                <div className="card ">
                    <Link to={"/articles/"+article._id} className="sp-center overflow">
                        <ShowImage item={article} itype={"w-100 home-img"} />
                    </Link>
                    <Link to={"/articles/"+article._id} >
                        <div className="container">
                            <div className="block">
                                <h2 className="color-black"><b>{article.title}</b></h2> 
                                <br/>
                                <p className="color-black">{moment(article.createdAt).fromNow()}</p> 
                            </div>
                        </div>
                    </Link>
                </div>
        </Fragment>
    )
}

export default ArticleHome