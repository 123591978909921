import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import Menu from '../Menu';
import '../style/About.css';
import instagram_logo from '../componentImages/instagram_logo.png';
import youtube_logo from '../componentImages/youtube_logo.png';

const About = () => {

    return (
        <Fragment>
            <Menu/>
            <div id="about" className="block">
                <div className="sp-w-center text-align-center">
                    <h1 className="about-title">We are two Italian geeks who never stop programming,
                    Have fun seeing our projects</h1>
                </div>
                <div className="sp-w-center social-about text-align-center">
                    <div className="block">
                        <div className="sp-w-center ">
                            <h3 className="txt-l ">Our Social</h3>
                        </div>
                        <div className="sp-w-center ">
                            <a href="https://instagram.com/spaghettihacks?igshid=xlpeqvu8x585">
                                <div className="icon">
                                    <img src={instagram_logo} alt="" className="icon-img"/>
                                </div>
                            </a>
                            <a href="https://www.youtube.com/channel/UCpZNBEbb_rCqkKwZpysQBHw/videos">
                                <div className="icon">
                                    <img src={youtube_logo} alt="" className="icon-img"/>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className="sp-w-center email text-align-center">
                    <h2>Our Email: spaghettihacks@gmail.com</h2>
                </div>
                <br/>
                <br/>
                <div className="sp-w-center">
                    <h1>We are here</h1>
                </div>
                <div className="sp-w-center">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23919.503942119976!2d12.883876976241474!3d41.46226018308173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13250c7874e571ab%3A0xaa91d7c1d4d7bd77!2s04100%20Latina%20LT!5e0!3m2!1sit!2sit!4v1574605672151!5m2!1sit!2sit" width="100%" height="450" frameborder="0"  allowfullscreen=""></iframe>
                </div>
            </div>
        </Fragment>
        
    )
}

export default About