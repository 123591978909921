import React,{Fragment} from 'react';
import './style/Home.css';

const Footer = () => {

    return (
        <Fragment>
            
            <footer id="footer" className="bg-color-black">
                <div className="block">
                    <div className="sp-w-center">
                        <div className="sp-h-center sp-fluid">
                            <div className="footer-logo">
                                <h1 className="color-white">SpaghettiHacks</h1>
                            </div>
                            <br/>
                            <div>   
                                <p className="color-white">All rights reserved Forward.Codes© 2019</p>
                            </div>
                            
                        </div>
                    </div>
                    <hr/>
                    <br/>
                    <div className="sp-w-center text-align-center">
                        <h4 className="color-white">See the about page to know more About us or check one of our contents</h4>
                    </div>
                    <br/>
                    <div className="sp-w-center">
                        <h3 className="color-white">Contacts: spaghettihacks@gmail.com</h3>
                    </div>
                    <br/>
                    <br/>
                    <div className="home-flexbox">

                    </div>
                </div>
            
            </footer>

        </Fragment>
        
    )
}

export default Footer
