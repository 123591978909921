export const getArticles = (query) => {
    return fetch('https://api.spaghettihacks.com/api/articles/'+query,
    {
        method: "GET",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        }
    }).then((res)=>{return res.json()})
}

export const postArticle = (token,article) => {
    return fetch('https://api.spaghettihacks.com/api/articles',
    {
        method: "POST",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:("Bearer "+token)
        },
        body:JSON.stringify(article)
    }).then((res)=>{return res.json()}).catch(err=>console.log(err))
}

export const addPhotoArticle = (id,image,token) => {
    return fetch('https://api.spaghettihacks.com/api/articles/photo/'+id,
    {
        method: "PUT",
        headers: {
            Accept:"application/json",
            Authorization:("Bearer "+token)
        },
        body:image
    }).then((res)=>{return res.json()}).catch(err=>console.log(err))
}

export const getArticle = (articleId) => {
    return fetch('https://api.spaghettihacks.com/api/articles/'+articleId,
    {
        method: "GET",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        }
    }).then((res)=>{return res.json()}).catch(err=>console.log(err))
}

export const deleteArticle = (token,articleId) => {
    return fetch('https://api.spaghettihacks.com/api/articles/'+articleId,
    {
        method: "DELETE",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:("Bearer "+token)
        }
    }).then((res)=>{return res.json()}).catch(err=>console.log(err))
}