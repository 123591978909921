import React, { Fragment } from 'react';

const Divisore = ({data}) => {

    return (
        <Fragment>
            <div id="Divisore" className="block">
                <hr/>
            </div>
        </Fragment>
        
    )
}

export default Divisore