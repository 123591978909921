import React, { Fragment } from 'react';
import Highlight from 'react-highlight.js';

const Codice = ({data}) => {

    return (
        <Fragment>
            <div id="Codice" className="block">
                <Highlight language={data.codetype}>
                    {data.text}
                </Highlight>
            </div>
        </Fragment>
        
    )
}

export default Codice